<template>
  <div class="login">
    <v-row class="appName">
      <v-col cols="8">
        <!-- <h3>Welcome to</h3>
        <h1>DTAC MSG Center</h1> -->
        <v-img class="imgAds" src="./../assets/images/Catoon_New.png" max-width="753" contain />
      </v-col>
      <v-col cols="3">
        <h2 class="my-10">User Login</h2>
        <v-form>
          <v-btn outlined class="btnLogin float-center ma-2" :href="`/api/users/login/ad`">
            LOGIN WITH AZURE AD
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import conf from '../helpers/config';
// import getEnv from '../helpers/env';

export default {
  name: 'UserLogin',

  components: {
    userLogin: () => import('./../components/login/login.vue')
  },

  data() {
    return {
      login: {
        username: '',
        password: ''
      },
      showPassword: false,
      rules: {
        required: value => !!value || 'Field, is Required.'
      }
      // urlAD: getEnv('VUE_APP_ADFS_LOGIN'), // conf.url.VUE_APP_ADFS_LOGIN || process.env.VUE_APP_ADFS_LOGIN,
    };
  },

  computed: {
    ...mapGetters({
      isAuth: 'auth/IS_AUTHENTICATED'
    })
  },

  methods: {
    async userLogin() {
      await this.$store.dispatch('auth/userLogin', this.login);

      if (this.isAuth) {
        this.$router.push({ name: 'Dashboard' });
      }
    }

    // async userLoginSaml() {
    //   this.$router.push({ })
    // },
    // userLogin() {
    //   this.$msal
    //     .loginPopup()
    //     .then((res) => console.log('login response', res))
    //     .catch((error) => console.log('error', error));
    // },
  }
};
</script>

<style></style>
